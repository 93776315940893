import { useAuthenticationService } from 'admin-portal-shared-services';
import axios, { AxiosResponse } from 'axios';
import { IGet } from './api.types';

export const useApi = () => {
  const auth = useAuthenticationService();
  const token = auth.getAuthHeader();

  const axiosInstance = axios.create({
    baseURL: window.origin,
    headers: {
      Authorization: token,
    },
  });

  const api = {
    get: function get<T>(props: IGet): Promise<AxiosResponse<T>> {
      return axiosInstance.get<T>(props.url, props.config);
    },
  };

  return api;
};
