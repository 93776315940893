/* eslint-disable react-hooks/rules-of-hooks */
import { useApi } from '@api';
import { useQuery } from '@tanstack/react-query';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { ISpreeadsheetRequestResponse } from './forecastSpreadsheetTemplate.types';

const FEATURE = 'FORECAST';
const QUERY_KEY_TEMPLATE = 'forecastSpreadsheetTemplate';
const URL = 'api/link-collab-forecast-bff/spreadsheet/generator';

export const ForecastSpreadsheetTemplate = () => {
  const { post } = useApi();
  const autenticantion = useAuthenticationService();

  const { user_country: country, language } = autenticantion.getUserCountryAndLanguage();

  const getFileData = async () => {
    const response = await post({
      url: URL,
      config: {
        headers: {
          country,
          feature: FEATURE,
          language,
          requestTraceId: `${QUERY_KEY_TEMPLATE}_${FEATURE}`,
        },
      },
    });

    return response.data;
  };

  const response = useQuery<ISpreeadsheetRequestResponse>(
    [QUERY_KEY_TEMPLATE, FEATURE],
    getFileData
  );

  return response;
};
