import { useApi } from '@api';
import { useMutation } from '@tanstack/react-query';
import { useAuthenticationService } from 'admin-portal-shared-services';

const FEATURE = 'FORECAST';

export const ForecastUploader = () => {
  const { postFormData } = useApi();
  const autenticantion = useAuthenticationService();
  const { user_country: country, language } = autenticantion.getUserCountryAndLanguage();

  const userId = autenticantion.getUserId();
  const vendorId = autenticantion.getVendorId();

  const { mutate, isLoading, isError, reset } = useMutation({
    mutationFn: async (fileToProcess: File) => {
      const data = new FormData();
      data.append('file', fileToProcess);

      const url = `api/link-collab-forecast-bff/spreadsheet/upload`;

      const response = await postFormData({
        url: url,
        config: {
          headers: {
            requestTraceId: 'ForecastFileUpload',
            feature: FEATURE,
            language,
            country,
            userId,
            vendorId,
          },
        },
        data,
      });

      return response.data;
    },
  });

  return { mutate, isLoading, isError, reset };
};
